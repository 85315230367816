function prepareTypesJSIndex(strs) {
  // Randomize
  strs.sort(function () {
    return 0.5 - Math.random();
  });

  // Prepare "data-typed" string
  var typed = {
    strings: strs,
    typeSpeed: 30,
    backSpeed: 30,
    backDelay: 700,
  };

  // To the element with id "typed1", add the "data-typed" attribute with the value of "typed"
  document
    .getElementById("typed1")
    .setAttribute("data-typed", JSON.stringify(typed));
}
window.prepareTypesJSIndex = prepareTypesJSIndex;

function pickTryBySearching(strs) {
  // Pick a random element from the strs array
  let randomIndex = Math.floor(Math.random() * strs.length);

  // Get the string at that index
  let randomString = strs[randomIndex];

  // Inject the value
  document.getElementById("pickedTryBySearching").innerHTML +=
    " " +
    `<span class="text-body-secondary mb-0 text-lowercase"> ${randomString}</span>`;
}

document.addEventListener("DOMContentLoaded", function () {
  let iwlt_strs = JSON.parse(document.getElementById("iwlt_strs").textContent);
  pickTryBySearching(iwlt_strs);
});

function computeSearch(clientSideSearchDb) {
  const options = {
    includeScore: true,
    keys: [
      "service_innerName",
      "service_title",
      "service_description",
      "service_w_category_inner_name",
      "service_dr_category_inner_name",
    ],
  };

  // Prefiltering
  // Get the checkboxes. Build an array with the selected categories. To build this array, get
  // the checked checkboxes inside the div with id "enhancedFilteringCategories"
  let checkboxes = document
    .getElementById("enhancedFilteringCategories")
    .getElementsByTagName("input");
  let selectedCategories = [];

  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked) {
      selectedCategories.push(checkboxes[i].value);
    }
  }

  if (selectedCategories.length === 0) {
    selectedCategories = clientSideSearchDb.map(
      (item) => item.service_w_category_inner_name
    );
  }

  let filteredData = clientSideSearchDb.filter((item) => {
    return selectedCategories.includes(item.service_w_category_inner_name);
  });

  // Get the radio buttons of popularity and age
  let popularity = document
    .getElementById("enhancedFilteringPopularity")
    .getElementsByTagName("input")[0].checked;
  let age = document
    .getElementById("enhancedFilteringAge")
    .getElementsByTagName("input")[0].checked;

  filteredData.sort((a, b) => {
    // Sort by popularity
    if (popularity) {
      if (a.service_popularity !== b.service_popularity) {
        return b.service_popularity - a.service_popularity;
      }
    } else {
      if (a.service_popularity !== b.service_popularity) {
        return a.service_popularity - b.service_popularity;
      }
    }

    // If popularity is equal, sort by age
    if (age) {
      return a.service_created_at - b.service_created_at;
    } else {
      return b.service_created_at - a.service_created_at;
    }
  });

  const e = document.getElementById("search-bar").value;
  const results = new Fuse(filteredData, options).search(e);

  // Update the UI: modify the shown cards
  let rowOfTheServicesCards = document.getElementById("rowOfTheServicesCards");

  // Clear the row
  rowOfTheServicesCards.innerHTML = "";

  // Add the cards following this HTML schema
  /*
  <div class="col-12 col-md-6 col-lg-4">
    <div class="card card-border border-primary shadow-lg mb-6 mb-md-8 lift lift-lg">
      <div class="card-body text-center">
        <!-- Heading -->
        <h4 class="fw-bold">
          service_title
        </h4>

        <!-- Text -->
        <p class="text-gray-700 mb-5">
          service_description
        </p>

        <!-- Badge -->
        <span class="badge rounded-pill text-bg-dark-subtle">
          <span class="h6 text-uppercase">
            service_w_category_inner_name
          </span>
        </span>

        <span class="badge rounded-pill text-bg-dark-subtle">
          <span class="h6 text-uppercase">
            service_dr_category_inner_name
          </span>
        </span>
      </div>
    </div>
  </div>
  */

  results.forEach((result) => {
    let outerCard = document.createElement("div");
    outerCard.classList.add("col-12", "col-md-6", "col-lg-4");

    let card = document.createElement("a");
    card.classList.add("card-body", "mt-auto");
    card.setAttribute("href", result.item.service_innerName);

    let cardInner = document.createElement("div");
    cardInner.classList.add(
      "card",
      "card-border",
      "border-primary",
      "shadow-lg",
      "mb-6",
      "mb-md-8",
      "lift",
      "lift-lg"
    );

    let cardBody = document.createElement("div");
    cardBody.classList.add(
      "card-body",
      "text-center",
      "href=" + result.item.service_innerName
    );

    let heading = document.createElement("h4");
    heading.classList.add("fw-bold");
    heading.textContent = result.item.service_title;

    let text = document.createElement("p");
    text.classList.add("text-gray-700", "mb-5");
    text.textContent = result.item.service_description;

    let badge1 = document.createElement("span");
    badge1.classList.add(
      "badge",
      "rounded-pill",
      `text-bg-${result.item.service_w_category_phrasal_color}-subtle`
    );
    let badge1Span = document.createElement("span");
    badge1Span.classList.add("h6", "text-uppercase");
    badge1Span.textContent = result.item.service_w_category_inner_name;
    badge1.appendChild(badge1Span);

    let space = document.createTextNode(" "); // Create a text node with a space

    let badge2 = document.createElement("span");
    badge2.classList.add(
      "badge",
      "rounded-pill",
      `text-bg-${result.item.service_dr_category_phrasal_color}-subtle`
    );
    let badge2Span = document.createElement("span");
    badge2Span.classList.add("h6", "text-uppercase");
    badge2Span.textContent = result.item.service_dr_category_inner_name;
    badge2.appendChild(badge2Span);

    cardBody.appendChild(heading);
    cardBody.appendChild(text);
    cardBody.appendChild(badge1);
    cardBody.appendChild(space); // Append the space
    cardBody.appendChild(badge2);

    cardInner.appendChild(cardBody);
    card.appendChild(cardInner);
    outerCard.appendChild(card);

    rowOfTheServicesCards.appendChild(outerCard);
  });

  // If no text is in the search bar
  // Just show everything that is in the selected categories
  if (e === "") {
    filteredData.forEach((item) => {
      let outerCard = document.createElement("div");
      outerCard.classList.add("col-12", "col-md-6", "col-lg-4");

      let card = document.createElement("a");
      card.classList.add("card-body", "mt-auto");
      card.setAttribute("href", item.service_innerName);

      let cardInner = document.createElement("div");
      cardInner.classList.add(
        "card",
        "card-border",
        "border-primary",
        "shadow-lg",
        "mb-6",
        "mb-md-8",
        "lift",
        "lift-lg"
      );

      let cardBody = document.createElement("div");
      cardBody.classList.add(
        "card-body",
        "text-center",
        "href=" + item.service_innerName
      );

      let heading = document.createElement("h4");
      heading.classList.add("fw-bold");
      heading.textContent = item.service_title;

      let text = document.createElement("p");
      text.classList.add("text-gray-700", "mb-5");
      text.textContent = item.service_description;

      let badge1 = document.createElement("span");
      badge1.classList.add(
        "badge",
        "rounded-pill",
        `text-bg-${item.service_w_category_phrasal_color}-subtle`
      );
      let badge1Span = document.createElement("span");
      badge1Span.classList.add("h6", "text-uppercase");
      badge1Span.textContent = item.service_w_category_inner_name;
      badge1.appendChild(badge1Span);

      let space = document.createTextNode(" "); // Create a text node with a space

      let badge2 = document.createElement("span");
      badge2.classList.add(
        "badge",
        "rounded-pill",
        `text-bg-${item.service_dr_category_phrasal_color}-subtle`
      );
      let badge2Span = document.createElement("span");
      badge2Span.classList.add("h6", "text-uppercase");
      badge2Span.textContent = item.service_dr_category_inner_name;
      badge2.appendChild(badge2Span);

      cardBody.appendChild(heading);
      cardBody.appendChild(text);
      cardBody.appendChild(badge1);
      cardBody.appendChild(space); // Append the space
      cardBody.appendChild(badge2);

      cardInner.appendChild(cardBody);
      card.appendChild(cardInner);
      outerCard.appendChild(card);

      rowOfTheServicesCards.appendChild(outerCard);
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  let wcategories = JSON.parse(
    document.getElementById("wcategories").textContent
  );

  // Add the wcategories to the select
  /*
  <div class="form-check w-100">
    <input class="form-check-input" type="checkbox" value="" id="team1">
      <label class="form-check-label w-100" for="team1">
        Team 1
      </label>
  </div>
  */

  let checkboxList = document.getElementById("enhancedFilteringCategories");

  wcategories.forEach((wcategory) => {
    let div = document.createElement("div");
    div.classList.add("form-check", "w-100");

    let input = document.createElement("input");
    input.classList.add("form-check-input");
    input.setAttribute("type", "checkbox");
    input.setAttribute("value", wcategory);
    input.setAttribute("id", wcategory);

    let label = document.createElement("label");
    label.classList.add("form-check-label", "w-100");
    label.setAttribute("for", wcategory);
    label.textContent = wcategory;

    div.appendChild(input);
    div.appendChild(label);

    checkboxList.appendChild(div);
  });

  ///////////////////////////////////////////////////////////////////////////

  // Parse the database for client side search
  let clientSideSearchDb = JSON.parse(
    document.getElementById("clientSideSearchDb").textContent
  );

  ///////////////////////////////////////////////////////////////////////////

  // Add the event listener to the search bar
  document.getElementById("search-bar").addEventListener("input", function (e) {
    computeSearch(clientSideSearchDb);
  });

  ///////////////////////////////////////////////////////////////////////////

  // Add the event listener to the checkboxes
  let checkboxes = document
    .getElementById("enhancedFilteringCategories")
    .getElementsByTagName("input");
  for (let i = 0; i < checkboxes.length; i++) {
    checkboxes[i].addEventListener("change", function (e) {
      document.getElementById("search-bar").dispatchEvent(new Event("input"));
    });
  }

  // Select the checkboxes based on the GET parameters on the URL
  let urlParams = new URLSearchParams(window.location.search);
  let selectedCategories = urlParams.getAll("catfilt");

  for (let i = 0; i < checkboxes.length; i++) {
    if (selectedCategories.includes(checkboxes[i].value)) {
      checkboxes[i].checked = true;
    }
  }

  ///////////////////////////////////////////////////////////////////////////

  // Add the event listener to the radio buttons of enhancedFilteringPopularity div
  let radioButtons = document
    .getElementById("enhancedFilteringPopularity")
    .getElementsByTagName("input");
  for (let i = 0; i < radioButtons.length; i++) {
    radioButtons[i].addEventListener("change", function (e) {
      document.getElementById("search-bar").dispatchEvent(new Event("input"));
    });
  }

  // Write the selected popularity in the p with tag enhancedFilteringPopularitySelected, if selected or remove it if not
  let selectedPopularityP = document.getElementById(
    "enhancedFilteringPopularitySelected"
  );
  for (let i = 0; i < radioButtons.length; i++) {
    radioButtons[i].addEventListener("change", function (e) {
      if (e.target.checked) {
        selectedPopularityP.innerHTML = ` <span class="text-body-secondary mb-0 text-uppercase">${e.target.value}</span>`;
      }
    });
  }

  // Trigger the change event on radio buttons
  radioButtons[0].dispatchEvent(new Event("change"));

  ///////////////////////////////////////////////////////////////////////////

  // Add the event listener to the radio buttons of enhancedFilteringAge div
  let radioButtonsAge = document
    .getElementById("enhancedFilteringAge")
    .getElementsByTagName("input");
  for (let i = 0; i < radioButtonsAge.length; i++) {
    radioButtonsAge[i].addEventListener("change", function (e) {
      document.getElementById("search-bar").dispatchEvent(new Event("input"));
    });
  }

  // Write the selected age in the p with tag enhancedFilteringAgeSelected, if selected or remove it if not
  let selectedAgeP = document.getElementById("enhancedFilteringAgeSelected");
  for (let i = 0; i < radioButtonsAge.length; i++) {
    radioButtonsAge[i].addEventListener("change", function (e) {
      if (e.target.checked) {
        selectedAgeP.innerHTML = ` <span class="text-body-secondary mb-0 text-uppercase">${e.target.value}</span>`;
      }
    });
  }

  // Trigger the change event on radio buttons
  radioButtonsAge[0].dispatchEvent(new Event("change"));

  ///////////////////////////////////////////////////////////////////////////

  // Add event handler to checkboxes in the div with id "enhancedFilteringCategories" when change.
  // I dont want to add the event to each checkbox, so I add it to the parent div.
  let enhanced_filtering_categories_no_filtering = JSON.parse(
    document.getElementById("enhanced_filtering_categories_no_filtering").textContent
  );

  document
    .getElementById("enhancedFilteringCategories")
    .addEventListener("change", function (e) {
      // Get the checkboxes.
      checkboxes = document
        .getElementById("enhancedFilteringCategories")
        .getElementsByTagName("input");

      // Filter the checkboxes array and keep only the checked ones
      checkboxes = Array.from(checkboxes).filter(
        (checkbox) => checkbox.checked
      );

      let selectedCategoriesP = document.getElementById(
        "enhancedFilteringCategoriesSelected"
      );
      selectedCategoriesP.innerHTML = "";

      // Iterate over the checkboxes
      for (let i = 0; i < checkboxes.length; i++) {
        // Is this the last for iteration?
        if (i === checkboxes.length - 1) {
          selectedCategoriesP.innerHTML += ` <span class="text-body-secondary mb-0 text-uppercase">${checkboxes[i].value}</span>`;
        } else {
          selectedCategoriesP.innerHTML += ` <span class="text-body-secondary mb-0 text-uppercase">${checkboxes[i].value},</span>`;
        }
      }

      if (selectedCategoriesP.innerHTML === "") {
        selectedCategoriesP.innerHTML = ` <span class="text-body-secondary mb-0 text-uppercase">${enhanced_filtering_categories_no_filtering}</span>`;
      }
    });

  // Write "No filtering" in the p with tag enhancedFilteringCategoriesSelected
  let selectedCategoriesP = document.getElementById(
    "enhancedFilteringCategoriesSelected"
  );
  selectedCategoriesP.innerHTML = ` <span class="text-body-secondary mb-0 text-uppercase">${enhanced_filtering_categories_no_filtering}</span>`;

  // Trigger the change event on enhancedFilteringCategories
  document
    .getElementById("enhancedFilteringCategories")
    .dispatchEvent(new Event("change"));

  ///////////////////////////////////////////////////////////////////////////

  // Trigger the input event to show all the services
  document.getElementById("search-bar").dispatchEvent(new Event("input"));
});
